<template>
  <v-main class="fill-height overflow-y-auto" style="background-color: #F6F7FB">
    <v-row>
      <v-col>
        <v-btn class="pl-0" text @click="$router.go(-1)" style="color: #173466">
          <v-icon size="x-large">mdi-chevron-left</v-icon>
          <span class="text-body-1"> {{ $t("back") }}</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-container>
      <view-header
        :title="$t('regionDetails.regionDetails')"
        :sub-title="'Additional information for this regions'"
      ></view-header>
      <v-row>
        <v-col cols="12" md="6">
          <v-row>
            <v-col
              class="text-h5"
              style="font-weight: bold"
              v-if="selectedRegion[0]"
            >
              {{ selectedRegion[0].properties.name }}
              <confirm-region-delete
                :region="selectedRegion[0]"
                :admin-role="adminRole"
                v-if="regionOwner || adminRole"
              ></confirm-region-delete>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col cols="4"> {{ $t("regionDetails.createdAt") }}:</v-col>
            <v-col cols="8" v-if="loaded">
              {{ orders[orders.length - 1].date }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4"> {{ $t("regionDetails.lastOrder") }}:</v-col>
            <v-col cols="8" v-if="loaded">
              {{ orders[0].date }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4"> {{ $t("regionDetails.area") }}:</v-col>
            <v-col cols="8" v-if="loaded">
              {{ geojsonSumArea }} km<sup>2</sup>
            </v-col>
          </v-row>
          <v-row v-if="selectedRegion[0].properties">
            <v-col cols="4"> {{ $t("regionDetails.services") }}:</v-col>
            <v-col cols="auto">
              <v-row
                v-for="service in selectedRegion[0].properties.services"
                :key="service"
              >
                <v-col class="pb-0">
                  {{ service }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-10 ml-0">
            <add-order-to-region
              :admin-role="adminRole"
              v-if="adminRole || regionOwner"
            />
          </v-row>
        </v-col>
        <v-col>
          <v-card
            outlined
            style="height: 30vh; width: 100%"
            :to="{
              name: 'MapView',
              params: { regionId: this.regionId }
            }"
          >
            <div id="view-map" style="height: 100%;"></div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-tabs
            :background-color="'rgba(0,0,0,0)'"
            class="mt-2"
            v-model="tab"
            color="deep-purple-accent-4"
            align-tabs="center"
          >
            <v-tab :value="1"
              >{{ $t("regionDetails.orderHistory") }} ({{ orders.length }})
            </v-tab>
            <!--              <v-tab :value="2">{{ $t("regionDetails.thresholdSettings") }}</v-tab>-->
          </v-tabs>
          <v-divider class="mt-4 mb-10" />
          <v-window v-model="tab">
            <v-container class="pa-0">
              <v-row v-if="tab === 0">
                <v-col>
                  <v-data-table
                    style="background-color: rgba(0,0,0,0)"
                    :headers="headers"
                    :items="orders"
                    @click:row="navigateTo"
                    :items-per-page="5"
                    class="elevation-0 row-pointer"
                  >
                    <template v-slot:item.status="{ item }">
                      <v-chip :color="getStatusColor(item.status)" dark>
                        {{ item.status }}
                      </v-chip>
                    </template>
                    <template v-slot:item.active="{ item }">
                      <v-icon v-if="item.active" color="green"
                        >mdi-check-circle</v-icon
                      >
                      <v-icon v-else>mdi-cancel</v-icon>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-window>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import MapboxConfig from "@/core/plugins/mapbox";
import bbox from "@turf/bbox";
import { mapActions } from "vuex";
import axios from "axios";
import creditMixin from "@/core/mixins/credit.mixin";
import geojsonArea from "@mapbox/geojson-area";
import addOrderToRegion from "@/core/components/order/addOrderToRegion.vue";
import ConfirmRegionDelete from "@/core/components/accountDetails/ConfirmRegionDelete.vue";
import Vue from "vue";
import ViewHeader from "@/core/components/ViewHeader.vue";
import * as Llet from "leaflet";

export default {
  name: "OrderHistory",
  components: { ViewHeader, ConfirmRegionDelete, addOrderToRegion },
  mixins: [creditMixin],
  data() {
    return {
      map: null,
      draw: null,
      tab: null,
      numberOfRegions: 0,
      selectedRegion: {},
      orders: [],
      headers: [
        {
          text: this.$t("regionDetails.orderDate"),
          align: "center",
          value: "date",
          width: "180px"
        },
        {
          text: this.$t("regionDetails.products"),
          align: "center",
          value: "products"
        },
        {
          text: this.$t("regionDetails.sensors"),
          align: "center",
          value: "sensors"
        },
        {
          text: this.$t("regionDetails.credits"),
          align: "center",
          value: "price"
        },
        {
          text: this.$t("regionDetails.numberScenes"),
          align: "center",
          value: "total_scenes"
        },
        {
          text: this.$t("regionDetails.status"),
          align: "center",
          value: "status"
        },
        {
          text: this.$t("regionDetails.active"),
          align: "center",
          value: "active"
        }
      ],
      maploaded: false,
      geojsonSumArea: null,
      regionTransactions: [],
      loaded: false
    };
  },
  computed: {
    regionId() {
      return this.$route.params.regionId;
    },
    adminRole() {
      const client = Vue.prototype.$appConfig.keycloakClient;
      const roles = this.$keycloak.resourceAccess?.[client]?.roles;
      if (roles) {
        return roles.includes("admin");
      }
      return false;
    },
    regionOwner() {
      const hasOwner = this.selectedRegion[0].properties.owner;
      return hasOwner;
    }
  },
  methods: {
    ...mapActions("management", ["getOrFetchRegions", "fetchUserProfile"]),
    navigateTo(item) {
      console.log(item);
      this.$router.push({
        name: "Missions",
        params: { regionId: this.regionId, orderId: item.id }
      });
    },
    getStatusColor(status) {
      if (status === "New") return "yellow";
      else if (status === "Running") return "blue";
      else if (status === "Done") return "green";
      else if (status === "Failed") return "red";
      else if (status === "Empty") return "orange";
      else if (status === "Restart") return "white";
    },
    disableMapInteraction(map, id, cursor) {
      map.dragging.disable();
      map.touchZoom.disable();
      map.doubleClickZoom.disable();
      map.scrollWheelZoom.disable();
      map.boxZoom.disable();
      map.keyboard.disable();
      if (map.tap) map.tap.disable();
      document.getElementById(id).style.cursor = cursor;
    },
    initMap() {
      const aoiBbox = bbox(this.selectedRegion[0].geometry);
      const initMap = Llet.map("view-map", {
        attributionControl: false,
        zoomControl: false
      }).fitBounds([
        [aoiBbox[1], aoiBbox[0]],
        [aoiBbox[3], aoiBbox[2]]
      ]);

      this.disableMapInteraction(initMap, "view-map", "cursor");

      Llet.tileLayer(
        `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${MapboxConfig.accessToken}`
      ).addTo(initMap);

      const geojsonLayer = Llet.geoJSON(this.selectedRegion[0], {
        style: {
          color: "#40ADD5",
          weight: 3
        }
      }).addTo(initMap);
      geojsonLayer.on("mouseover", function(event) {
        event.layer.getElement().style.cursor = "auto";
      });
      return initMap;
    },

    async getOrders() {
      const response = await axios.get(`/orders/?regionId=${this.regionId}`);
      return response.data.reverse(); // data of last order
    },
    datetimeFormat(item) {
      item = item.split(".")[0].replace("T", "\u00A0");
      return item;
    },
    defineSensors(sensors) {
      sensors.forEach((sensor, index) => {
        sensors[index] = this.$t(`sensors.` + sensor);
      });
      return sensors;
    },
    capitalizeAndSpaceOrderProducts(arr) {
      return arr.map(item => item.toUpperCase()).join(", ");
    }
  },
  async created() {
    const regions = await this.getOrFetchRegions();
    this.selectedRegion = regions.features.filter(
      region => region.id == this.regionId
    );

    this.orders = await this.getOrders();
    this.orders.forEach(order => {
      order.date = this.datetimeFormat(order.created_at);
      if (order.sensors) {
        order.sensors = this.defineSensors(order.sensors).join(", ");
      }
      order.products = this.capitalizeAndSpaceOrderProducts(order.products);
    });
    let sumArea = 0;
    this.initMap();
    sumArea = geojsonArea.geometry(this.orders[0].aoi);
    this.geojsonSumArea = Math.round((sumArea / 1000000) * 1000) / 1000; // square kilometer
    this.maploaded = true;

    let transactions = [];
    this.userAccounts = await this.fetchUserAccounts();
    for (const account of this.userAccounts) {
      transactions.push(
        await this.fetchAccountTransactions(account.id, "eoapp")
      );
    }
    this.loaded = true;
  }
};
</script>

<style scoped>
.full-height {
  height: 80vh !important;
  width: 90vw !important;
}

.nowrapTable .text-center {
  white-space: nowrap;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
