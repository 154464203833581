<template>
  <div>
    <v-btn
      class="noPadding"
      v-if="hasLastOrder || adminRole"
      text
      color="primary"
      :to="{
        name: 'RegionOrder',
        params: { regionId: this.regionId }
      }"
      :title="$t('order.addDataToOrder')"
    >
      <v-icon class="pb-1 pt-1" dense> mdi-plus-circle</v-icon>
      {{ $t("order.orderNewData") }}
    </v-btn>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "addOrderToRegion",
  props: {
    adminRole: Boolean
  },
  data() {
    return {
      regionName: "",
      regionDescription: "",
      regionProducts: [],
      hasLastOrder: false
    };
  },
  computed: {
    regionId() {
      return this.$route.params.regionId;
    }
  },
  methods: {
    async getOrderInfo() {
      const response = await axios.get(`/orders/?regionId=${this.regionId}`);
      return response.data[response.data.length - 1]; // data of last order
    }
  },
  async created() {
    const order = await this.getOrderInfo();
    if (order == undefined) {
      this.hasLastOrder = false;
    } else {
      this.hasLastOrder = true;
    }
  }
};
</script>

<style scoped>
.noPadding {
  padding: 0 !important;
}
</style>
